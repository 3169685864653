import { LazyComponent } from '@components/LazyComponent';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

function LazyPage() {
  return <LazyComponent factory={() => import('./Statement')} />;
}

export const ROUTE_Statement = RouterService.create(
  `${RouterService.BASE_PATH}/employees/:employeeId/statement`,
);

export const RouteComponent_Statement = (
  <Route path={ROUTE_Statement.path} element={<LazyPage />} />
);

export const RouteComponent_Statement_Index = <Route index element={<LazyPage />} />;
