import { LazyComponent } from '@components/LazyComponent';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

function LazyPage() {
  return <LazyComponent factory={() => import('./FailedTransactions')} />;
}

export const ROUTE_FailedTransactions = RouterService.create(
  `${RouterService.BASE_PATH}/employees/:employeeId/failed-transactions`,
);

export const RouteComponent_FailedTransactions = (
  <Route path={ROUTE_FailedTransactions.path} element={<LazyPage />} />
);
