import { SurveyStore as UISurveyStore } from '@flash-tecnologia/hros-web-ui-v2';
import AuthService from '@services/AuthService';
import { TrackingService } from '@services/TrackingService';

type Props = {
  type: SurveyType;
  questions: Array<{
    id: string;
    title: string;
    placeholder?: string;
    scores?: [
      string | null,
      string | null,
      string | null,
      string | null,
      string | null,
    ];
    type?: 'text';
  }>;
  params?: Record<string, string>;
};

type SurveyValue = Parameters<
  React.ComponentProps<typeof UISurveyStore>['onSubmit']
>[number];

export function MultiQuestionsSurvey({ type, questions, params }: Props) {
  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  async function handleSubmit(data: SurveyValue) {
    if (!company?.id || !employeeId) return false;

    TrackingService.track(
      type,
      {
        message: data.message,
        ...data.answers,
        ...params,
      },
      {
        employeeId,
        companyId: company.id,
      },
    );

    return true;
  }

  return (
    <UISurveyStore
      isLoading={false}
      questions={questions}
      onSubmit={handleSubmit}
      showTeamEvaluation={false}
    />
  );
}

type SurveyType = 'card-statement-feedback';
