import { Box, Icon, Typography } from '@components';
import { Dot, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import { serializeTransaction, Transaction } from './serializeTransaction';
import * as SC from './styled';

type Props = Transaction;
export function Movement(props: Props) {
  const transaction = serializeTransaction(props);

  return (
    <Box display="grid" gridTemplateColumns="32px auto" alignItems="center" gap="xs4">
      <Icon size={32} {...transaction.icon} background={transaction.icon.background} />

      <div>
        <SC.Label
          color="neutral.20"
          weight={600}
          isLineThrough={props.status === 'CANCELED' || props.status === 'CANCELLED'}>
          {transaction.label}
        </SC.Label>

        <Box display="flex" gap="xs5" alignItems="center">
          {transaction.description && (
            <Typography.Body4 color="neutral.40" weight={600}>
              {transaction.description}
            </Typography.Body4>
          )}

          {props.status === 'CANCELED' ||
            (props.status === 'CANCELLED' && (
              <>
                <Dot variant="gray" />
                <Typography.Body4 color="neutral.40" weight={600}>
                  {props.movement === 'DEPOSIT' ? 'Cancelado' : 'Cancelada'}
                </Typography.Body4>
              </>
            ))}
          {transaction.highlight && transaction.description && <Dot variant="gray" />}

          {transaction.highlight && (
            <Tooltip title={transaction.highlight.description}>
              <SC.TooltipContainer>
                <Typography.Body4 color="feedback.info.40" weight={600}>
                  {transaction.highlight.title}
                </Typography.Body4>

                <Icon size={12} name="IconInfoCircle" color="neutral.40" />
              </SC.TooltipContainer>
            </Tooltip>
          )}
        </Box>
      </div>
    </Box>
  );
}

Movement.get = serializeTransaction;
