import { RouterOutputs } from '@services/corporateCard';

import { Tag } from '../Tag';

type Status =
  | RouterOutputs['corporateStatement']['cardsStatement']['items'][number]['status']
  | RouterOutputs['corporateStatement']['companyStatement']['items'][number]['status']
  | 'FINISHED';

type Props = {
  size?: 'md' | 'sm' | 'lg' | 'xs';
  status: Status;
};

export function TagStatus(props: Props) {
  const status = mappedStatus[props.status] ?? mappedStatus['processing'];

  return (
    <Tag variant={status.variant} hasLeftDotIcon size={props.size ?? 'xs'}>
      {status.label}
    </Tag>
  );
}

const mappedStatus: Record<
  Status,
  { label: string; variant: React.ComponentProps<typeof Tag>['variant'] }
> = {
  AUTHORIZED: { label: 'Confirmada', variant: 'success' },
  CANCELED: { label: 'Depósito cancelado', variant: 'gray' },
  COMPLETED: { label: 'Completa', variant: 'success' },
  FINISHED: {
    label: 'Completa',
    variant: 'success',
  },
  finished: {
    label: 'Completa',
    variant: 'success',
  },
  CONFIRMED: { label: 'Confirmada', variant: 'success' },
  CREATED: { label: 'Criada', variant: 'info' },
  DECLINED: { label: 'Compra recusada', variant: 'red' },
  FAILED: { label: 'Falha', variant: 'error' },
  PARTIALLY_CANCELED: { label: 'Parcialmente Cancelada', variant: 'gray' },
  PROCESSING: {
    label: 'Agendado',
    variant: 'purple',
  },
  processing: {
    label: 'Agendado',
    variant: 'purple',
  },
  SETTLED: { label: 'Liquidada', variant: 'success' },
  REVERTED: { label: 'Estornada', variant: 'gray' },
  PENDING: {
    label: 'Pendente',
    variant: 'gray',
  },
  pending: {
    label: 'Pendente',
    variant: 'gray',
  },
  WAITING: {
    label: 'Pagamento pendente',
    variant: 'blue',
  },
  waiting: {
    label: 'Pagamento pendente',
    variant: 'blue',
  },
  CANCELLED: {
    label: 'Depósito cancelado',
    variant: 'red',
  },
  canceled: {
    label: 'Depósito cancelado',
    variant: 'red',
  },
  cancelled: {
    label: 'Depósito cancelado',
    variant: 'red',
  },
  PAID: {
    label: 'Agendado',
    variant: 'purple',
  },
  paid: {
    label: 'Agendado',
    variant: 'purple',
  },
  PREVIEW: {
    label: 'Prévia',
    variant: 'gray',
  },
  preview: {
    label: 'Prévia',
    variant: 'gray',
  },
  REPROVED: { label: 'Reprovado', variant: 'primary' },
  reproved: {
    label: 'Reprovado',
    variant: 'primary',
  },
  EXPIRED: {
    label: 'Pagamento expirado',
    variant: 'red',
  },
  expired: {
    label: 'Pagamento expirado',
    variant: 'red',
  },
  DEPOSIT_CANCELLED: {
    label: 'Cancelado',
    variant: 'primary',
  },
  depositCancelled: {
    label: 'Cancelado',
    variant: 'primary',
  },
};
