/* eslint-disable prettier/prettier */
export type MCCCategory =
  | 'GROCERY'
  | 'MEAL'
  | 'MOBILITY'
  | 'EDUCATION'
  | 'CULTURE'
  | 'HEALTH'
  | 'CONVENIENCE'
  | 'WITHDRAW'
  | 'NOT_AVAILABLE';

export const translatedMCC: Record<MCCCategory, string> = {
  GROCERY: 'Alimentação',
  MEAL: 'Refeição',
  MOBILITY: 'Mobilidade',
  EDUCATION: 'Educação',
  CULTURE: 'Cultura',
  HEALTH: 'Saúde',
  CONVENIENCE: 'Conveniência',
  WITHDRAW: 'Saque',
  NOT_AVAILABLE: 'Outro',
} as const;

export const translatedToMCC: Record<string, MCCCategory> = {
  'alimentação': 'GROCERY',
  'refeição': 'MEAL',
  'mobilidade': 'MOBILITY',
  'educação': 'EDUCATION',
  'cultura': 'CULTURE',
  'saúde': 'HEALTH',
  'conveniência': 'CONVENIENCE',
  'saque': 'WITHDRAW',
  'outro': 'NOT_AVAILABLE',
} as const;

export function getTranslatedMCC<T extends MCCCategory>(mcc: T | string) {
  return translatedMCC[mcc ?? 'NOT_AVAILABLE'];
}

export function getFromTranslated(category: string): MCCCategory {
  return translatedToMCC[category] ?? 'NOT_AVAILABLE';
}
