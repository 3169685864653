import { IconProps } from '@components/Icon';
import { RouterOutputs } from '@services/corporateCard';
import { format } from '@utils/format';
import { getFromTranslated, MCCCategory } from '@utils/mcc';
import dayjs from 'dayjs';

type TransactionView = {
  label: string;
  description?: string;
  highlight?: {
    title: string;
    description;
  };
  icon: {
    name: IconProps['name'];
    color: IconProps['color'];
    background?: IconProps['background'];
  };
};

type RawTransaction =
  RouterOutputs['corporateStatement']['cardsStatement']['items'][number];

export type Transaction = {
  description?: string;
  type: 'CREDIT' | 'DEBIT';
  movement: RawTransaction['movement'];
  cardType?: 'PLASTIC' | 'VIRTUAL';
  automaticCredit?: RawTransaction['automaticCredit'];
  creditDate?: RawTransaction['creditDate'];
  debitDate?: RawTransaction['debitDate'];
  automaticDebitDate?: RawTransaction['automaticDebitDate'];
  creditType?: RawTransaction['creditType'];
  category?: string; // MCCCategory;
  status?: RawTransaction['status'];
  paymentMethod?: RawTransaction['paymentMethod'];
};

export function serializeTransaction(transaction: Transaction): TransactionView {
  switch (transaction.movement) {
    case 'OPEN_LOOP_PAYMENT':
      const translatedCategory = mappedOpenLoopPayment[
        transaction.category ?? getFromTranslated[transaction.category]
      ] as TransactionView;

      return {
        label: transaction.description,
        description:
          transaction.status === 'CANCELED'
            ? `${translatedCategory.label} • Cancelada`
            : translatedCategory.label,
        icon: translatedCategory.icon,
      };
    case 'DEPOSIT':
      if (automaticCreditTypes.includes(transaction.creditType)) {
        return {
          label:
            transaction.creditType === 'automaticCreditFixedValue'
              ? 'Depósito recorrente complementar'
              : 'Depósito recorrente',
          icon: {
            name: 'IconRepeat',
            color: 'neutral.40',
            background: 'neutral',
          },
          description: format.date.repeatsEveryDay(
            transaction.automaticCredit?.date ?? transaction.creditDate,
          ),
        };
      }

      return {
        label: 'Depósito único',
        icon: {
          name: 'IconTransferIn',
          color: 'feedback.success.40',
          background: 'success',
        },
        description: transaction.automaticDebitDate
          ? `Expira em ${format.date.format(transaction.automaticDebitDate)}`
          : undefined,
        highlight: getDepositHighlight(transaction),
      };

    case 'CASH_OUT_PIX':
      return {
        label: 'Pix',
        icon: {
          name: 'Pix',
          color: 'neutral.40',
          background: 'neutral',
        },
        description: 'Retirada de saldo',
      };

    case 'CASH_OUT_TED':
      return {
        label: 'TED',
        icon: {
          name: 'IconCash',
          color: 'neutral.40',
          background: 'neutral',
        },
        description: 'Retirada de saldo',
      };

    default:
      if (transaction.description === 'estorno colaborador') {
        return {
          label: transaction.automaticDebitDate
            ? 'Expiração de saldo de pessoa'
            : 'Estorno de saldo de pessoa',
          icon: {
            name: transaction.automaticDebitDate ? 'IconHourglass' : 'IconArrowBackUp',
            color: 'feedback.success.40',
            background: 'success',
          },
        };
      }
      return {
        ...mappedOpenLoopPayment.NOT_AVAILABLE,
        label: transaction.description ?? mappedOpenLoopPayment.NOT_AVAILABLE.label,
      };
  }
}

const mappedOpenLoopPayment: Record<MCCCategory, TransactionView> = {
  GROCERY: {
    label: 'Alimentação',
    icon: {
      name: 'IconShoppingCart',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  MEAL: {
    label: 'Refeição',
    icon: {
      name: 'IconToolsKitchen2',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  MOBILITY: {
    label: 'Mobilidade',
    icon: {
      name: 'IconCar',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  EDUCATION: {
    label: 'Educação',
    icon: {
      name: 'IconSchool',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  CULTURE: {
    label: 'Cultura',
    icon: {
      name: 'IconMasksTheater',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  HEALTH: {
    label: 'Saúde',
    icon: {
      name: 'IconStethoscope',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  CONVENIENCE: {
    label: 'Conveniência',
    icon: {
      name: 'IconBuildingStore',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  WITHDRAW: {
    label: 'Retirada de saldo',
    icon: {
      name: 'IconCash',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
  NOT_AVAILABLE: {
    label: 'Outro',
    icon: {
      name: 'IconBuildingStore',
      color: 'neutral.40',
      background: 'neutral',
    },
  },
};

function getDepositHighlight(transaction: Transaction): TransactionView['highlight'] {
  if (transaction.status !== 'WAITING' || transaction.paymentMethod === 'FLASH_CASH') {
    return;
  }

  if (transaction.paymentMethod === 'PIX') {
    return {
      title: 'Pendente',
      description: `Pague até ${format.date.format(transaction.creditDate)}`,
    };
  }

  const expiredDate = dayjs(transaction.creditDate).endOf('day').subtract(2, 'days');
  const isExpired = expiredDate.isBefore(dayjs());

  if (!isExpired) {
    return {
      title: 'Pendente',
      description: `Pague até ${format.date.format(expiredDate.toDate())}`,
    };
  }

  return {
    title: 'Vencido',
    description: `Boleto vencido`,
  };
}

const automaticCreditTypes = ['automaticCredit', 'automaticCreditFixedValue'];
