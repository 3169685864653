import { Box, LinkButton, Tag, Typography } from '@components';

type Props = {
  info?: {
    label: string;
    variant: React.ComponentProps<typeof Tag>['variant'];
  };
  translatedLabel?: string;
  reportName?: string;
  reportUrl?: string;
};
export function Accountability(props: Props) {
  if (!props.info) {
    return <Typography.Body4>-</Typography.Body4>;
  }

  const statusLabel = props.translatedLabel ?? props.info.label;
  return (
    <Box display="flex" gap="xs4">
      <Tag hasLeftDotIcon variant={props.info.variant} size="xs">
        {statusLabel}
      </Tag>

      {statusLabel !== 'Pendente' && props.reportName && props.reportUrl && (
        <LinkButton
          iconPosition="left"
          icon="IconExternalLink"
          size="sm"
          target="_black"
          variant="neutral"
          href={props.reportUrl}>
          {props.reportName}
        </LinkButton>
      )}
    </Box>
  );
}
