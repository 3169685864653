import NiceModal from '@ebay/nice-modal-react';
import { external } from '@flash-hros/corporate-card';
import { RouteComponent_EmployeeStatement } from '@pages/employee-statement';
import { RouteComponent_Statement } from '@pages/statement';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AcquisitionGuard } from './AcquisitionGuard';

function AppRouter() {
  return (
    <BrowserRouter>
      <NiceModal.Provider>
        <external.ExternalRoot>
          <external.Version.Provider>
            <Routes>
              <Route element={<AcquisitionGuard />}>
                {RouteComponent_Statement()}
                {RouteComponent_EmployeeStatement()}
              </Route>
            </Routes>
          </external.Version.Provider>
        </external.ExternalRoot>
      </NiceModal.Provider>
    </BrowserRouter>
  );
}

export default AppRouter;
