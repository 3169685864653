import { Button } from './styled';

type Props = {
  onClick(): void;
};

export function ViewMore(props: Props) {
  return (
    <Button size="md" icon="IconInfoCircle" type="secondary" onClick={props.onClick} />
  );
}
