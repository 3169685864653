import { LazyComponent } from '@components/LazyComponent';
import { PermissionsGuard } from '@components/PermissionGuard';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

import {
  RouteComponent_CardStatements,
  RouteComponent_CardStatements_Index,
} from './CardStatements';
import { RouteComponent_FailedMovements } from './FailedMovements';
import { RouteComponent_WalletStatements } from './WalletStatements/index';

function LazyPage() {
  return <LazyComponent factory={() => import('./Statement')} />;
}

export const ROUTE_Statement = RouterService.create(`${RouterService.BASE_PATH}`);

export function RouteComponent_Statement() {
  return (
    <Route
      path={ROUTE_Statement.path}
      element={
        <PermissionsGuard
          permissions={[
            'expense_manage_corporate_card_balances',
            'expense_manage_corporate_cards',
          ]}>
          <LazyPage />
        </PermissionsGuard>
      }>
      {RouteComponent_CardStatements_Index}
      {RouteComponent_CardStatements}
      {RouteComponent_WalletStatements}
      {RouteComponent_FailedMovements}
    </Route>
  );
}
