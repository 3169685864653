/**
 * Notion features description
 * https://www.notion.so/flashapp/Cart-o-Corporativo-Feature-Flags-4ba618e62d1b4f0692344afacedca0d3?pvs=4
 */
import * as featureFlag from '@flash-tecnologia/feature-flags';

import AuthService from './AuthService';
import EnvService from './EnvService';

const enableFailedTransactionsTab = 'FLASH_OS_ENABLE_FAILED_TRANSACTIONS_TAB';

const FEATURES = {
  enableFailedTransactionsTab,
};

export default class FeatureFlagService {
  static getFlag(flagName: keyof typeof FEATURES) {
    featureFlag.WithFlag({
      flagName: FEATURES[flagName],
    });
    return featureFlag.useFlag({
      flagName: FEATURES[flagName],
    });
  }
}

export function FeatureFlagProvider(props: { children: React.ReactNode }) {
  const employeeId = AuthService.useEmployeeId();
  const company = AuthService.useCompany();

  return (
    <featureFlag.FlagsProvider
      appName="flash-os-corporate-statement"
      token={EnvService.UNLEASH_TOKEN}
      url={EnvService.UNLEASH_URL}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId: employeeId ?? '',
        properties: {
          employeeNanoId: employeeId ?? '',
          companyNanoId: company?.id ?? '',
          environment: EnvService.BUILD_ENV,
        },
      }}>
      {props.children}
    </featureFlag.FlagsProvider>
  );
}
