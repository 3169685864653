import { z } from 'zod';

const EnvSchema = z.object({
  BUILD_ENV: z.enum(['production', 'staging', 'development']),
  API_URL: z.string(),
  UNLEASH_URL: z.string(),
  UNLEASH_TOKEN: z.string(),
});

/** Environment variables. Used for secrets */
const EnvService = EnvSchema.parse({
  BUILD_ENV: process.env.BUILD_ENV,
  API_URL: process.env.API_URL,
  UNLEASH_URL: process.env.UNLEASH_URL,
  UNLEASH_TOKEN: process.env.UNLEASH_BENEFITS_PROXY_KEY,
});

export default EnvService;
