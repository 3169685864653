import { LazyComponent } from '@components/LazyComponent';
import { PermissionsGuard } from '@components/PermissionGuard';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { z } from 'zod';

import { RouteComponent_FailedTransactions } from './EmployeeStatement/tabs/FailedTransactions';
import {
  RouteComponent_Statement,
  RouteComponent_Statement_Index,
} from './EmployeeStatement/tabs/Statement';

function LazyPage() {
  return (
    <LazyComponent factory={() => import('./EmployeeStatement/EmployeeStatement')} />
  );
}

export const ROUTE_EmployeeStatement = RouterService.create(
  `${RouterService.BASE_PATH}/employees/:employeeId`,
  {
    params: {
      employeeId: zod(z.string()).defined(),
    },
  },
);

export function RouteComponent_EmployeeStatement() {
  return (
    <Route
      path={ROUTE_EmployeeStatement.path}
      element={
        <PermissionsGuard
          permissions={[
            'expense_manage_corporate_card_balances',
            'expense_manage_corporate_cards',
          ]}>
          <LazyPage />
        </PermissionsGuard>
      }>
      {RouteComponent_Statement_Index}
      {RouteComponent_Statement}
      {RouteComponent_FailedTransactions}
    </Route>
  );
}
