import { colors, ThemesType } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Header = styled.div<{ gap?: keyof ThemesType['spacings'] }>`
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xs} 0px`};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme, gap }) => theme.spacings[gap]};

  @media screen and (max-width: 671px) {
    padding: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const Body = styled.div`
  width: 671px;

  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xs} ${theme.spacings.m}`};

  @media screen and (max-width: 671px) {
    width: 100vw;
    padding: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const CloseContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;

export const ActionsContainer = styled.div`
  z-index: 1299;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  margin-top: auto;
  bottom: 0;
  background-color: ${colors.get('neutral.100')};
  border-top: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${colors.get('neutral.90')}`};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.m}`};
`;
