import {
  Icons,
  IconsProps,
  LinkButton as UIButton,
  LinkButtonProps as UIButtonProps,
} from '@flash-tecnologia/hros-web-ui-v2';

type Size = 'sm' | 'md' | 'lg';

type LinkButtonProps = {
  id?: string;
  variant: UIButtonProps['variant'];
  href?: string;
  onClick?(): void;
  children: string;
  target?: UIButtonProps['target'];
  icon?: IconsProps['name'];
  /**
   * Position icon
   * @default right
   */
  iconPosition?: 'left' | 'right';
  /**
   * Button size
   * @default md
   */
  size?: Size;
  /**
   * if false underline not show
   *
   * @default true
   */
  underlined?: boolean;
};

export function LinkButton(props: LinkButtonProps) {
  const mappedSize: Record<Size, UIButtonProps['size']> = {
    sm: 'small',
    md: 'medium',
    lg: 'large',
  };

  const mappedIconSize = {
    sm: 16,
    md: 16,
    lg: 24,
  };

  return (
    <UIButton
      style={{
        width: 'max-content',
        whiteSpace: 'nowrap',
        flexDirection: props.iconPosition === 'left' ? 'row-reverse' : 'row',
        gap: '2px',
      }}
      {...props}
      underlined={props.underlined}
      size={mappedSize[props.size ?? 'lg']}>
      {props.children}

      {props.icon && (
        <Icons name={props.icon} size={mappedIconSize[props.size ?? 'lg']} />
      )}
    </UIButton>
  );
}
