import { Box, Icon, IconProps, Typography } from '@components';

type Props =
  | {
      description: string;
      label: string;
      icon: IconProps['name'];
    }
  | { label: string };

export function Payment(props: Props) {
  if (!('description' in props)) {
    return (
      <Typography.Body4 color="neutral.40" weight={600}>
        {props.label}
      </Typography.Body4>
    );
  }

  return (
    <Box display="grid" gridTemplateColumns="32px auto" alignItems="center" gap="xs4">
      <Icon name={props.icon} size={32} color="neutral.40" background="neutral" />

      <div>
        <Typography.Body4 color="neutral.30" weight={600}>
          {props.label}
        </Typography.Body4>

        <Typography.Body4 color="neutral.40" weight={600}>
          {props.description}
        </Typography.Body4>
      </div>
    </Box>
  );
}
