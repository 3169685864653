import { Typography } from '@components/Typography';
import styled, { css } from 'styled-components';

export const Label = styled(Typography.Body4)<{ isLineThrough?: boolean }>`
  ::first-letter {
    text-transform: uppercase;
  }

  text-wrap: nowrap;

  ${({ isLineThrough }) =>
    isLineThrough &&
    css`
      text-decoration-line: line-through;
    `}
`;
