import { Box } from '@components';
import { ColorToken } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

type Props = {
  amount: string;
  isCanceled?: boolean;
  color: ColorToken;
};

export function Amount(props: Props) {
  return (
    <Box display="flex" justifyContent="flex-end">
      <SC.Label weight={700} isLineThrough={props.isCanceled} color={props.color}>
        {props.amount}
      </SC.Label>
    </Box>
  );
}
