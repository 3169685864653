import { Icon } from '@components/Icon';
import { LinkButton } from '@components/LinkButton';
import {
  Breadcrumbs as UIBreadcrumbs,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

/** Breadcrumbs for representing the hierarchy of a page or content */
export function Breadcrumbs(props: Props) {
  return (
    <UIBreadcrumbs
      breadcrumbs={props.breadcrumbs.map(breadcrumb =>
        breadcrumb.onClick ? (
          <LinkButton
            variant="neutral"
            onClick={breadcrumb.onClick}
            underlined
            key={breadcrumb.label}>
            {breadcrumb.label}
          </LinkButton>
        ) : (
          <Typography.Body4 color="neutral.50" key={breadcrumb.label}>
            {breadcrumb.label}
          </Typography.Body4>
        ),
      )}
      separator={<Icon name="IconChevronRight" size={24} color="neutral.50" />}
    />
  );
}

type Props = {
  breadcrumbs: readonly {
    label: string;
    onClick?: () => void;
  }[];
};
