import { Box, Icon, IconProps, Typography } from '@components';
import { Dot, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

import { serializeTransaction } from './serializeTransaction';
import * as SC from './styled';

type Props = {
  label: string;
  icon: Pick<Required<IconProps>, 'background' | 'color' | 'name'>;
  description?: string;
  highlight?: {
    title: string;
    description: string;
  };
  isLineThrough?: boolean;
};

export function Movement(props: Props) {
  return (
    <Box display="grid" gridTemplateColumns="32px auto" alignItems="center" gap="xs4">
      <Icon size={32} {...props.icon} background={props.icon.background} />

      <div>
        <SC.Label color="neutral.20" weight={600} isLineThrough={props.isLineThrough}>
          {props.label}
        </SC.Label>

        <Box display="flex" gap="xs5" alignItems="center">
          {props.description && (
            <Typography.Body4 color="neutral.40" weight={600}>
              {props.description}
            </Typography.Body4>
          )}
        </Box>
        {props.highlight && props.description && <Dot variant="gray" />}

        {props.highlight && (
          <Tooltip title={props.highlight.description}>
            <SC.TooltipContainer>
              <Typography.Body4 color="feedback.info.40" weight={600}>
                {props.highlight.title}
              </Typography.Body4>

              <Icon size={12} name="IconInfoCircle" color="neutral.40" />
            </SC.TooltipContainer>
          </Tooltip>
        )}
      </div>
    </Box>
  );
}
Movement.get = serializeTransaction;
