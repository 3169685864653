import { Box, Icon, IconProps, Typography } from '@components';
import { Transaction } from '@pages/statement/CardStatements/contexts/CardStatements/type';
import dayjs from 'dayjs';

type Props = {
  movement: Transaction['movement'];
  card?: {
    cardName: string;
    type: 'PLASTIC' | 'VIRTUAL';
    lastFourDigits: string;
  };
  employeeName?: string;
  adminName?: string;
  creditDate?: Date;
  creditType?: Transaction['creditType'];
  paymentMethod?: Transaction['paymentMethod'];
};

const today = dayjs();

export function Payment(props: Props) {
  const transaction = serializeTransaction(props);

  return (
    <Box display="grid" gridTemplateColumns="32px auto" alignItems="center" gap="xs4">
      <Icon name={transaction.icon} size={32} color="neutral.40" background="neutral" />

      <div>
        <Typography.Body4 color="neutral.30" weight={600}>
          {transaction.label}
        </Typography.Body4>

        {transaction.description && (
          <Typography.Body4 color="neutral.40" weight={600}>
            {transaction.description}
          </Typography.Body4>
        )}
      </div>
    </Box>
  );
}

type TransactionView = {
  label: string;
  description?: string;
  icon: IconProps['name'];
};

function serializeTransaction(transaction: Props): TransactionView {
  switch (transaction.movement) {
    case 'OPEN_LOOP_PAYMENT':
      return transaction.card?.type === 'PLASTIC'
        ? {
            label: 'Cartão físico',
            icon: 'IconCreditCard',
            description: transaction.employeeName,
          }
        : {
            label: 'Cartão virtual',
            icon: 'IconDeviceMobile',
            description: `${transaction.card.cardName} • Final ${transaction.card.lastFourDigits}`,
          };
    case 'CASH_OUT_PIX':
      return {
        label: 'Pix',
        icon: 'Pix',
        description: `Realizado por ${transaction.employeeName}`,
      };

    case 'DEPOSIT':
      return serializeByCreditType(transaction);

    default:
      return {
        label: 'Carteira corporativa',
        icon: 'IconWallet',
        description:
          transaction.adminName && transaction.adminName !== '-'
            ? `Realizado por ${transaction.adminName}`
            : undefined,
      };
  }
}

function serializeByCreditType(transaction: Props): TransactionView {
  const adminName = transaction.adminName
    ? `por ${transaction.adminName}`
    : 'de forma automática';

  const description = today.isAfter(transaction.creditDate)
    ? `Agendado ${adminName}`
    : `Realizado ${adminName}`;

  switch (transaction.paymentMethod) {
    case 'BILLET':
      return {
        label: 'Boleto',
        icon: 'BarcodeMethod',
        description,
      };
    case 'FLASH_CASH':
      return {
        label: 'Carteira corporativa',
        icon: 'IconWallet',
        description,
      };
    case 'PIX':
      return {
        label: 'Pix',
        icon: 'Pix',
        description,
      };
    default:
      if (
        transaction.creditType === 'automaticCredit' ||
        transaction.creditType === 'automaticCreditFixedValue'
      ) {
        return {
          label: 'Carteira corporativa',
          icon: 'IconWallet',
          description,
        };
      }

      return {
        label: 'Outro',
        icon: 'IconWallet',
      };
  }
}
