import { Box, Modal } from '@components';
import { MultiQuestionsSurvey } from '@components/MultiQuestionSurvey';
import ModalService from '@services/ModalService';

export function Feedback() {
  const controller = ModalService.useModalController();

  const questions: {
    id: string;
    title: string;
    placeholder?: string;
    scores?: [
      string | null,
      string | null,
      string | null,
      string | null,
      string | null,
    ];
    type?: 'text';
  }[] = [
    {
      id: 'ease_of_use',
      title:
        '[1/2] Como você avalia a facilidade de uso da página de Extratos de Cartão Corporativo?',
      scores: ['Péssima', 'Ruim', 'Regular', 'Boa', 'Ótima'],
    },
    {
      id: 'meets_needs',
      title: '[2/2] O quanto essa área atende às suas necessidades?',
      scores: [
        'Não atende em nada',
        '',
        'Atende parcialmente',
        '',
        'Atende totalmente',
      ],
    },
  ];

  return (
    <Modal
      isOpen
      highlight={{
        description: 'Extratos de cartão corporativo',
        size: 'compact',
        variant: 'announcement',
        icon: 'IconMessageChatbot',
      }}
      size="sm"
      title="Deixe sua opinião"
      onClose={() => controller.remove()}>
      <Box display="flex" justifyContent="flex-start">
        <MultiQuestionsSurvey type="card-statement-feedback" questions={questions} />
      </Box>
    </Modal>
  );
}

export const FeedbackModal = ModalService.create(Feedback);
