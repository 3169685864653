import { Typography } from '@components';
import { format } from '@utils/format';

type Props = {
  date: Date;
};

type NewProps = {
  date: string;
  hours: string;
};

export function Date(props: Props | NewProps) {
  return (
    <div>
      <Typography.Body4 color="neutral.20" weight={600}>
        {typeof props.date === 'string' ? props.date : format.date.format(props.date)}
      </Typography.Body4>

      <Typography.Body4 color="neutral.40">
        {'hours' in props ? props.hours : format.date.hours(props.date)}
      </Typography.Body4>
    </div>
  );
}
